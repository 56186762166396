<template>
  <div class="characters-trend">
    <v-tabs v-model="statusStatusPanelIndex">
      <v-tab v-for="(item, i) in healthStatus" :key="i">
        {{item.title}}
      </v-tab>
    </v-tabs>
    <div id="statuspanel"></div>
  </div>
</template>
<script>
import {api} from "@/utils/api";
export default {
  name: 'customers-v2-characters-trend',
  data() {
    return {
      healthStatus: [
        { statusName: 'blood_fat', title: '血脂', options: {} },
        // { statusName: 'homocysteine', title: '同型半胱氨酸', options: {} },
        { statusName: 'blood_pressure', title: '血压', options: {} },
        { statusName: 'uric_acid', title: '尿酸', options: {} },
        { statusName: 'blood_sugar', title: '血糖', options: {} },
        // { statusName: 'vitamin_D', title: '维生素D', options: {} },
        { statusName: 'bmi', title: 'BMI', options: {} }
      ],
      statusStatusPanelChart: null,
      statusStatusPanelIndex: 0
    }
  },
  watch: {
    statusStatusPanelIndex() {
      this.autoSwitchStatusPanel()
    }
  },
  mounted() {
    this.statusStatusPanelChart = this.$echarts.init(document.getElementById('statuspanel'))
    this.buildStatusPanel()

    let itv = setInterval(() => {
      if(this._isDestroyed) return clearInterval(itv)

      this.statusStatusPanelChart.resize()
    }, 1000)
  },
  methods: {
    async buildStatusPanel() {
      const trendData = await api.post(`individuals/${this.$route.params.id}/get_health_status_trend/`, {
        characterCategoryList: this.healthStatus.map(hs => hs.statusName)
      })
      for (let hs of this.healthStatus) {
        const { statusName, title } = hs
        const { data, range } = trendData[statusName]
        const legend = []
        const xAxis = []
        const series = []
        data.map(({ character_id, character_name, happen_date, instance_value }) => {
          const value = Number(instance_value) || 0

          const rangeDefine = range.find(r => r.character_id === character_id)

          let color = undefined
          if (rangeDefine) {
            if (value > rangeDefine.range_max || value < rangeDefine.range_min) {
              color = 'red'
            }
          }

          let n = character_name
          if (n.indexOf('（') > -1) {
            n = n.substring(0, n.indexOf('（'))
          }
          const td = series.find(td => td.name === n)
          if (td) {
            if (!td.data.some(d => d.happen_date === happen_date)) {
              td.data.push({ value, happen_date, itemStyle: { color } })
            }
          } else {
            legend.push(n)
            series.push({
              name: n,
              type: 'line',
              data: [{ value, happen_date, itemStyle: { color } }]
            })
          }
          if (!xAxis.find(x => x === happen_date)) {
            xAxis.push(happen_date)
          }
        })
        hs.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: legend
          },
          grid: {
            top: 32,
            bottom: 30
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis
          },
          yAxis: [{
            position: 'left',
            type: 'value',
            axisLine: {
                show: true
            }
          }],
          series
        }
      }
      this.autoSwitchStatusPanel()
    },
    autoSwitchStatusPanel() {
      const { healthStatus, statusStatusPanelChart, statusStatusPanelIndex } = this
      const options = healthStatus[statusStatusPanelIndex].options
      statusStatusPanelChart.clear()
      statusStatusPanelChart.setOption(options)
      // setTimeout(() => {
      //   let next = statusStatusPanelIndex + 1
      //   if (next > healthStatus.length - 1) {
      //     next = 0
      //   }
      //   this.statusStatusPanelIndex = next
      //   this.autoSwitchStatusPanel()
      // }, 3000)
    }
  }
}
</script>
<style lang="scss" scoped>
.characters-trend {
  margin-bottom: 24px;
  #statuspanel {
    height: 240px;
  }
}
</style>