<template>
    <v-card>
      <v-card-title class="body-2 font-weight-medium" style="padding-bottom: 0px">
        <v-subheader>结果解读</v-subheader>
      </v-card-title>
      <v-card-text  style="padding-bottom: 0px">
        <v-subheader>总分: {{cal_result["total_score"]}}</v-subheader>
        <v-subheader>结论: {{cal_result["summary"]}}</v-subheader>
      </v-card-text>
    </v-card>
</template>

<script>

export default {
  name: "RecordResultView",
  props: {
    instance: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cal_result: {},
      summary: '',
      total_score: 0
    }
  },
  mounted() {
    console.log(this.instance.cal_result)
    this.cal_result = JSON.parse(this.instance.cal_result)
  }
};
</script>
