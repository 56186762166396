<template>
  <div class="questionnaire-group-page">
    <TitleComp
      :title="template_config.template_name"
      :customer="customer"
      :instance="instance"
      :desc="template_config.template_desc"
      v-if="hasTitle"
    />
    <span v-show="false">{{ isShow }}</span>
    <v-card-text style="padding: 0 8px 0 8px">
      <v-stepper v-model="group_step" vertical v-if="isRendered" style="webkit-box-shadow: 0 0 0 0; box-shadow: 0 0 0 0; padding-left: 0px">
        <template v-for="group in template_config.group_templates">
          <v-stepper-step :key="`${group.sid}-step`" :complete="group_step > group.step" :step="group.step" v-if="genderMatch(group.group_crowd)" edit-icon="fa-check">
            {{group.group_name}}
          </v-stepper-step>
          <v-stepper-content :key="`${group.sid}-content`" :step="group.step" v-if="genderMatch(group.group_crowd)">
            <v-form ref="form" v-model="group.valid" lazy-validation v-on:submit.prevent="onSubmit">
              <v-layout row wrap>
                <template v-for="character in group.characters">
                  <CharacterComp
                    :key="character.sid"
                    v-if="genderMatch(character.character_crowd) && character.is_should_visible"
                    :c="character"
                  />
                </template>
              </v-layout>
              <v-layout>
                <v-flex d-flex xs12 class="pt-12">
                  <v-btn v-if="group.step > 1" color="teal" class="mr-2" @click.stop="group_step -= 1">
                    <span class="white--text">上一步</span>
                  </v-btn>
                  <v-btn v-if="group.step < steps" color="teal" class="mr-2" @click.stop="doCheckStep(group.step)">
                    <span class="white--text">下一步</span>
                  </v-btn>
                  <v-btn v-if="hasTitle && group.step === steps" color="primary" class="mr-2" @click.stop="doSubmit" :disabled="!group.valid || dialog_save" :loading="dialog_save">
                    保存
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-card-text>
  </div>
</template>
<script>
import TitleComp from './Title'
import CharacterComp from './Character'
import mixin from './common_mixin.js'
export default {
  name: "questionnaire-step-group",
  props: {
    hasTitle: {
      type: Boolean,
      default: false
    }
  },
  components: { TitleComp, CharacterComp },
  mixins: [mixin],
  data() {
    return {
      steps: 100,
      group_step: 1,
      previous_data: null,
      isRendered: false
    }
  },
  async mounted() {
    await this.loadBaseData()
    if (this.instanceId) {
      await this.loadInstanceData()
    } else {
      await this.loadPreviousData()
    }
    let stepper = 0;
    for (let group of this.template_config.group_templates) {
      group.step = 0;
      if (this.genderMatch(group.group_crowd)) {
        stepper += 1;
        group.valid = true;
        group.step = stepper;
      }
    }
    this.steps = stepper
    await this.constructData()
    this.isRendered = true
  },
  methods: {
    doCheckStep(groupStep) {
      if (this.$refs.form[groupStep - 1].validate()) {
        if (this.group_step < this.steps) {
          this.group_step += 1
        }
      }
    }
  }
};
</script>