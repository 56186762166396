<template>
  <v-card style="padding:24px;margin-top:16px;width:100%;">
    <v-layout row wrap>
      <v-flex v-for="tool in tools" :key="tool.icon" xs4 md2 style="text-align: center;">
        <v-btn fab dark depressed color="primary">
          <v-icon :size="28">{{tool.icon}}</v-icon>
        </v-btn>
        <div style="margin-top:12px;font-size:14px;"><a>{{tool.desc}}</a></div>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: 'common-tools',
  data() {
    return {
      tools: [
        { icon: 'mdi-flash-outline', desc: '基础能量消耗计算', to: '' },
        { icon: 'mdi-run', desc: '常见运动热量消耗', to: '' },
        { icon: 'mdi-water-plus', desc: '常见低/高升糖食物', to: '' },
        { icon: 'mdi-pill', desc: '常见营养素功效', to: '' },
        { icon: 'mdi-chart-bell-curve-cumulative', desc: '重要检测指标解读', to: '' },
        { icon: 'mdi-medical-bag', desc: '常见药物副作用', to: '' },
      ]
    }
  }
}
</script>