<template>
  <div class="questionnaire-one-page">
    <TitleComp
      :title="template_config.template_name"
      :customer="customer"
      :desc="template_config.template_desc"
      :instance="instance"
      v-if="hasTitle"
    >
      <template v-if="canNew">
        <v-btn color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save" @click.stop="doSubmit">
          保存
        </v-btn>
        <v-btn v-if="template_config.display_type==='M'" color="primary" class="mr-2" :disabled="!valid || dialog_save" :loading="dialog_save" @click.stop="doSubmitNew">
          保存并新增
        </v-btn>
      </template>
    </TitleComp>
    <span v-show="false">{{ isShow }}</span>
    <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="onSubmit">
      <v-card-text style="margin: 8px" v-for="group in template_config.group_templates" :key="group.sid">
        <v-subheader v-if="group.is_default_visible && genderMatch(group.group_crowd) && !group.group_no.includes('-DEFAULT')" class="font-weight-bold">
          {{group.group_name}}
        </v-subheader>
        <v-layout row wrap class="vlayout" v-if="genderMatch(group.group_crowd)">
          <template v-for="character in group.characters">
            <CharacterComp
              :key="character.sid"
              v-if="genderMatch(character.character_crowd) && character.is_should_visible"
              :c="character"
            />
          </template>
        </v-layout>
      </v-card-text>
    </v-form>
  </div>
</template>
<script>
import TitleComp from './Title'
import CharacterComp from './Character'
import mixin from './common_mixin.js'
export default {
  name: "questionnaire-step-group",
  components: { TitleComp, CharacterComp },
  mixins: [mixin],
  props: {
    hasTitle: {
      type: Boolean,
      default: false
    },
    canNew: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      valid: true,
      previous_data: null
    };
  },
  async mounted() {
    await this.loadBaseData()
    if (this.instanceId) {
      await this.loadInstanceData()
    } else {
      await this.loadPreviousData()
    }
    await this.constructData()
  },
  methods: {
    doCheckStep(groupStep) {
      if (this.$refs.form[groupStep - 1].validate()) {
        if (this.group_step < this.steps) {
          this.group_step += 1
        }
      }
    },
    async doSubmitNew() {
      await this.doSubmit()
      this.$refs.form.resetValidation()
      await this.$store.dispatch('setReloadTicket', Date())
    }
  }
};
</script>