<template>
  <div>
    <!-- <info-record :id="id" :sid="sid"></info-record><v-card style="width:100%;margin-bottom:24px;"> -->
    <v-card style="width:100%;margin-bottom:24px;" v-if="instance.cal_result">
      <RecordResult :instance="instance"></RecordResult>
    </v-card>
    <v-card style="width:100%;margin-bottom:24px;">
      <QuestionnaireOnePage
          v-if="instance.template && instance.template.sid"
          :individualId="id"
          :characterTemplateId="instance.template.sid"
          :instanceId="sid"
          hasTitle
          readonly
          :canNew="false"
      />
    </v-card>
    <template d-flex xs12 v-if="template_config.display_type==='M'">
      <combo-record :template_id="template_config.sid" action_type="view"></combo-record>
    </template>
  </div>
</template>
<script>
import InfoRecord from "@/components/record/ViewRecordOnePage";
import QuestionnaireOnePage from '@/components/questionnaire/OnePage.vue'
import ComboRecord from "@/components/record/ComboRecord";
import RecordResult from "@/components/record/RecordResult.vue";
import { api } from "@/utils/api";
import InfoReport from "@/components/record/ViewReport.vue";
import InfoReviewReport from "@/components/record/ListReviewReport.vue";

export default {
  name: "ViewRecord",
  components: {
    InfoReviewReport,
    InfoReport,
    InfoRecord,
    QuestionnaireOnePage,
    ComboRecord,
    RecordResult
  },
  props: {
    id: {
      type: String
    },
    sid: {
      type: String
    }
  },
  data() {
    return {
      instance: {},
      template_config: {}
    };
  },
  watch: {
    '$route.params'() {
      this.instance = {}
      this.template_config = {}
      this.doLoadData()
    }
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      // 获取实例信息
      this.instance = await api.get("template_instances/" + this.sid + "/?", null)
      //获取模版信息
      this.template_config = await api.get("character_templates/" + this.instance.template.sid + "/get_template_info/?", null)
    }
  }
};
</script>
