<template>
  <v-row class="customers-v2" v-if="isInit">
    <v-col sm="4">
      <Base class="info-card" />
    </v-col>
    <v-col sm="4">
      <v-card class="info-card customers-v2-health-tag" style="text-align:center">
        <v-card-title class="card-title">
          功能导航
        </v-card-title>
        <v-btn
          v-for="r in guidBtnList"
          :key="r.name"
          fab
          class="ma-4"
          :color="guidBtnColor(r.name)"
          @click="$router.push({ name: r.name })"
        >{{r.line0}}<br/>{{r.line1}}</v-btn>
      </v-card>
    </v-col>
    <v-col sm="4">
      <HealthTag class="info-card" />
    </v-col>
    <v-col sm="12" class="mainjob">
      <div class="mainjob0">
        <router-view />
      </div>
      <div :class="{ mainjob1: true, fold: fold }">
        <div class="side-fold-title" @click="handleSwitchFold">
          客户指标
          <v-icon class="ic">mdi-arrow-right</v-icon>
        </div>
        <v-card class="side-card">
          <v-card-title class="card-title">
            指标趋势
          </v-card-title>
          <CharactersTrend/>
        </v-card>
        <v-card class="side-card" v-for="ng in newestGroup" :key="ng.title">
          <v-card-title class="card-title">
            {{ng.title}}
          </v-card-title>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center">指标</th>
                <th class="text-center">结果</th>
                <th class="text-center">正常参考值</th>
                <th class="text-center" width="105">检测日期</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in ng.data.slice((ng.page - 1) * newestPageSize, ng.page * newestPageSize)" :key="d.character_name">
                <td class="text-center">{{d.character_name}}</td>
                <td class="text-center">{{d.instance_value}}<label v-if="d.trend" style="color:red;">{{d.trend}}</label></td>
                <td class="text-center">{{d.normal_range}}</td>
                <td class="text-center">{{d.happen_date}}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-pagination
            v-if="!fold && ng.data.length > newestPageSize"
            v-model="ng.page"
            :length="Math.ceil(ng.data.length / newestPageSize)"
          ></v-pagination>
        </v-card>
      </div>
      <Drawer
        v-model="showDrawer"
        :title="drawer.title"
        width="520px"
        draggable
        scrollable
        :mask="false"
        @on-close="handleDrawerClose"
      >
        <component
          v-if="drawer.type"
          :is="drawer.type"
          v-bind="drawer.params"
        />
      </Drawer>
    </v-col>
  </v-row>
</template>

<script>
import Base from './base.vue'
import HealthTag from './health-tag.vue'
import CharactersTrend from './characters-trend.vue'
import Instancce from './instance.js'
import Drawer from '@/components/iview/drawer.vue'
import CharacterEditRecord from "@/views/records/EditRecord.vue"
import CharacterViewRecord from "@/views/records/ViewRecord.vue"
import CurativeEffectReport from "@/views/health/CurativeEffectReport";
import StageEvaluationReport from "@/views/health/StageEvaluationReport";
import WebReportFrameContain from "@/views/web-report/frame-contain";
import { getNewestCharactersData } from '@/components/customer/data.js'
export default {
  name: "CustomerDashboard",
  components: { Base, HealthTag, CharactersTrend, Drawer, CharacterEditRecord, CharacterViewRecord, CurativeEffectReport, StageEvaluationReport, WebReportFrameContain },
  inject: ['setDrawer'],
  props: {
    id: {
      type: String
    }
  },
  provide() {
    return {
      instance: this.instance,
      individualId: this.id,
      handleShowDrawer: this.handleShowDrawer.bind(this),
      handleDrawerClose: this.handleDrawerClose.bind(this)
    }
  },
  data() {
    return {
      guidBtnList: [],
      instance: new Instancce(this.id),
      isInit: false,
      fold: true,
      newestGroup: [],
      newestPageSize: 5,
      showDrawer: false,
      drawer: { type: '', title: '', params: {} }
    }
  },
  async mounted() {
    const children = this.$router.getRoutes().filter(r => r.parent?.name == '客户信息')
    children.map(c => {
      if(c.name == '报告管理' && !this.$store.getters.getPermission('reportinstance', 'view')) {
        return
      }
      if(c.name == '健康管理' && !this.$store.getters.getPermission('manageplan', 'view')) {
        return
      }
      if(c.name == '干预建议' && !this.$store.getters.getPermission('individualmedicineadvice', 'view')) {
        return
      }
      if(c.name == '健康评估' && !this.$store.getters.getPermission('peoplehealthinfo', 'view')) {
        return
      }
      this.guidBtnList.push({
        name: c.name,
        line0: c.name.slice(0, 2),
        line1: c.name.slice(2, 4)
      })
    })
    await this.instance.initBaseInfo()
    this.isInit = true
    this.setDrawer(false)
    const newestGroup = await getNewestCharactersData(this.id)
    newestGroup.map(ng => {
      ng.page = 1
    })
    this.newestGroup = newestGroup
  },
  beforeRouteUpdate(to, from, next) {
    if (this.showDrawer) {
      this.handleDrawerClose()
      next(from)
    } else {
      next()
    }
  },
  beforeDestroy() {
    this.setDrawer(true)
  },
  methods: {
    guidBtnColor(name) {
      if (this.$route.matched.find(t => t.name == name)) {
        return 'primary'
      } else {
        return ''
      }
    },
    handleToDataPanel() {
      this.$router.push({ path: `/customer/${this.$route.params.id}/datapanel` })
    },
    handleSwitchFold() {
      this.fold = !this.fold
    },
    handleShowDrawer(title, type, data) {
      this.drawer = {
        type, title, params: data
      }
      this.showDrawer = true
    },
    handleDrawerClose() {
      // 恢复状态，保证能够正确渲染
      this.drawer = { type: '', title: '', params: {} }
      this.showDrawer = false
    }
  }
};
</script>
<style lang="scss" scoped>
.customers-v2 {
  .info-card {
    height: 100%;
  }
  ::v-deep .dflex {
    display: flex;
    align-items: center;
    .flex1 {
      flex: 1;
    }
  }
  ::v-deep .card-title {
    padding: 4px 8px 0 16px;
    font-size: 16px;
  }
  .mainjob {
    display: flex;
    width: 100%;
    .mainjob0 {
      flex: 1;
    }
    .mainjob1 {
      max-width: 32.33%;
      min-width: 40px;
      margin-left: 24px;
      position: relative;
      width: 100%;
      transition: width .3s;
      .side-card {
        margin-left: 40px;
        margin-bottom: 24px;
      }
      .side-fold-title {
        width: 40px;
        word-break: break-all;
        padding: 12px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        cursor: pointer;
        transition: all .3s;
        position: absolute;
        top: 0;
        left: -1px;
        z-index: 1;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        .ic {
          transition: transform .3s;
          transform: rotate(0deg);
          right: 2px;
        }
        &:hover {
          background: #5E35B1;
          color: #fff;
          .ic { color: #fff; }
        }
      }
    }
    .mainjob1.fold {
      width: 0;
      .ic {
        transform: rotate(180deg);
      }
    }
  }
}
</style>