<template>
  <v-layout row>
    <v-col sm="6">
      <v-card class="panelcard">
        能量摄入
        <div id="dietaryrecommen"></div>
      </v-card>
      <v-card class="panelcard" v-if="dietaryData.overall_energy">
        每日进食量推荐（约{{dietaryData.overall_energy}}大卡），大约相当于：
        <div class="daysuggest">
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/mainfood.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.main_food_unit)" :key="i" class="numimg" src="@/assets/boxing-1.png" />
                <img v-if="dietaryData.main_food_unit % 1 === 0.5" class="numimg" src="@/assets/boxing-2.png" />
              </div>
              {{dietaryData.main_food_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/vegetable.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img class="numimg" src="@/assets/two-hands.png" />
                <img class="numimg" src="@/assets/two-hands.png" />
                <img class="numimg" src="@/assets/two-hands.png" />
              </div>
              {{dietaryData.vegetable_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/fruit.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.fruit_unit)" :key="i" class="numimg" src="@/assets/boxing-1.png" />
                <img v-if="dietaryData.fruit_unit % 1 === 0.5" class="numimg" src="@/assets/boxing-2.png" />
              </div>
              {{dietaryData.fruit_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/protein.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.protein_food_unit)" :key="i" class="numimg" src="@/assets/hand-1.png" />
                <img v-if="dietaryData.protein_food_unit % 1 === 0.5" class="numimg" src="@/assets/hand-2.png" />
              </div>
              {{dietaryData.protein_food_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/milk.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.milk_unit)" :key="i" class="numimg" src="@/assets/bottle.png" />
              </div>
              {{dietaryData.milk_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/nut.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.nut_unit)" :key="i" class="numimg" src="@/assets/thrumb.png" />
              </div>
              {{dietaryData.nut_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/oil.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.oil_unit)" :key="i" class="numimg" src="@/assets/cap.png" />
              </div>
              {{dietaryData.oil_desc}}
            </div>
          </div>
          <div class="daysuggest-row">
            <img class="mainimg" src="@/assets/salt.png" />
            <div class="daysuggest-row-f1">
              <div>
                <img v-for="i in Math.floor(dietaryData.salt_unit)" :key="i" class="numimg" src="@/assets/finger.png" />
              </div>
              {{dietaryData.salt_desc}}
            </div>
          </div>
        </div>
      </v-card>
      <div v-show="dietaryData.age <= 65">
        <v-card class="panelcard" v-if="dietaryData.age <= 65">
          个性化运动推荐
          <p><label style="color:#1AA9DC">P</label>-您的有效心率为：{{dietaryData.heart_min}}-{{dietaryData.heart_max}}次/分。</p>
          <p><label style="color:#FF6D00">E</label>-<label v-html="dietaryData.e_desc"/></p>
          <p v-if="dietaryData.total_k_cal > 0"><label style="color:#60B630">T</label>-建议每周运动{{dietaryData.times_per_week}}天，每天运动时间为（任选一种）：</p>
          <v-layout row wrap>
            <template v-for="sport in sportList">
              <v-flex sm4 style="text-align:center;font-size: 14px;padding: 12px;" :key="sport.name" v-if="sport.minutes > 0">
                <img :src="sport.pic" />
                {{sport.name}}{{sport.minutes}}分钟
              </v-flex>
            </template>
          </v-layout>
          <p style="margin: 16px 0">每天的运动可拆分为2次或多次完成</p>
          <img src="@/assets/pet-1.png" />
          <p>运动风险警示：反复低血糖发作、急性心脑血管疾病期或膝踝关节损伤者等，请咨询专业人士，规避运动风险。</p>
        </v-card>
        <v-card class="panelcard" v-if="dietaryData.age < 80 && dietaryData.age > 65">
          运动原则
          <p>遵循“轻”“柔”“慢”“短”原则，即强度要轻，动作要柔，频率要慢，时间要短。</p>
          <p>建议下午4-5点。尽量不要在早晨，尤其是冬天的早晨，因为这是心脑血管疾病的高发时段。</p>
        </v-card>
        <v-card class="panelcard" v-if="dietaryData.age < 80 && dietaryData.age > 65">
          推荐运动
          <p><font name="SYM">平衡锻炼，</font>有助于预防跌倒，如太极拳或走直线练习，每次10-20分钟，每周2-3次。</p>
          <p><font name="SYM">力量训练，</font>可以预防肌肉萎缩，用较轻的物体做上臂举重练习，每次10-20下，每周3-5次。</p>
          <p><font name="SYM">柔韧锻炼，</font>可以缓解肌肉僵硬，保养关节，如弯腰摸脚等，每次5分钟，每周3-5次。</p>
          <p><font name="SYM">有氧锻炼，</font>能有效预防心脑血管疾病，如散步、打扫房间和园艺劳动等，每次20-30分钟，每周2-3次。</p>
          <p>* 温馨提示：以上运动方案需要在医生指导下进行，运动前评估身体情况，运动中注意防范，避免运动相关损伤。</p>
        </v-card>
      </div>
    </v-col>
    <v-col sm="6">
      <v-card class="nutrientcard">
        <div class="flex" style="margin-bottom: 16px;">
          <div class="flex1">营养素推荐</div>
          <template v-if="showAddMedicineAdvice">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" @click="handleSave" style="margin-right:16px;">
                  mdi-content-save-outline
                </v-icon>
              </template>
              <span>保存</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" v-on="on" @click="showAddMedicineAdvice = false">
                  mdi-clipboard-list-outline
                </v-icon>
              </template>
              <span>列表</span>
            </v-tooltip>
          </template>
          <v-tooltip bottom v-else>
            <template v-if="has_add_right" v-slot:activator="{ on }">
              <v-icon color="primary" v-on="on" @click="handleAddAdvice">mdi-plus-circle</v-icon>
            </template>
            <span>新增推荐</span>
          </v-tooltip>
        </div>
        <div class="add-advice" v-if="showAddMedicineAdvice">
          <v-text-field label="推荐主题" v-model="addAdviceForm.advice_name" />
          <!-- <v-text-field label="小程序商城链接" v-model="addAdviceForm.shop_url" /> -->
          <v-text-field label="备注" v-model="addAdviceForm.note" />
          <div class="nutrientitem" v-for="(item, i) in addAdviceForm.medicine_details" :key="i">
            <div class="flex">
              <div class="flex1">
                <v-autocomplete
                  :label="`营养素${i+1}`"
                  v-model="item.tempData.searchedObj"
                  :items="item.tempData.searchedData"
                  :loading="item.tempData.searchLoading"
                  :search-input.sync="item.tempData.searchKeyword"
                  @update:search-input="() => handleSearchInput(item)"
                  @change="() => handleSearchChange(item)"
                  item-text="medicine_fixed_name"
                  item-value="sid"
                  hide-details
                  hide-no-data
                  hide-selected
                  return-object
                  cache-items
                ></v-autocomplete>
              </div>
              可选&nbsp;
              <v-switch
                color="primary"
                v-model="item.is_optional"
              ></v-switch>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="error" v-on="on" @click="() => handleRemoveAdvice(item, i)">
                    mdi-close
                  </v-icon>
                </template>
                <span>删除</span>
              </v-tooltip>
            </div>
            <template v-if="item.medicine">
              <div class="detail">
                <!-- 【剂量】： -->
                <v-select
                  style="width: 70px;"
                  v-model="item.take_rate"
                  dense
                  :items="item.tempData.rateOptions"
                  hide-details
                />
                服用
                <v-text-field
                  style="width: 15px;"
                  v-model="item.take_count_min"
                  dense
                  hide-details
                />
                <!-- -
                <v-text-field
                  style="width: 15px;"
                  v-model="item.take_count_max"
                  dense
                  hide-details
                /> -->
                次，每次
                <v-text-field
                  style="width: 15px;"
                  v-model="item.recommend_number"
                  dense
                  hide-details
                />
                <v-select
                  style="width: 60px;"
                  v-model="item.recommend_unit"
                  dense
                  hide-details
                  :items="item.tempData.unitOptions"
                />
                <!-- ，最大剂量
                <v-text-field
                  style="width: 15px;"
                  v-model="item.max_dosage"
                  dense
                  hide-details
                />
                <v-select
                  style="width: 50px;"
                  v-model="item.recommend_unit"
                  dense
                  hide-details
                  :items="item.tempData.unitOptions"
                /> -->
              </div>
              <!-- <p class="desc" :title="item.tempData.efficacy">【功效】：{{item.tempData.efficacy}}</p>
              <p class="desc" :title="item.tempData.ingredient">【成份】：{{item.tempData.ingredient}}</p>
              <p class="desc" :title="item.tempData.specification">【规格】：{{item.tempData.specification}}</p> -->
              <!-- <v-text-field
                label="服用说明"
                v-model="item.take_comments"
                hide-details
              /> -->
              <v-text-field
                label="备注"
                v-model="item.note"
                hide-details
              />
            </template>
          </div>
          <div class="nutrientitem flex">
            <v-btn class="flex1" outlined small color="indigo" style="border-style: dashed;" @click="handleAddMedicine">
              添加营养素
            </v-btn>
          </div>
        </div>
        <template v-else>
          <div v-if="medicineAdviceList.length == 0">
            暂无数据
          </div>
          <v-expansion-panels v-else :value="0">
            <v-expansion-panel
              v-for="(item,i) in medicineAdviceList"
              :key="i"
              @change="() => handleLoadAdviceDetail(item)"
            >
              <v-expansion-panel-header>
                {{item.advice_name}}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="item.medicine_details">
                <v-toolbar dense flat>
                  <p style="font-size: 14px;">
                    {{item.created_date}}
                    &nbsp;
                    {{item.note}}
                  </p>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom v-if="item.status == 'INIT' && has_publish_right">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="tempDelete = item;dialog_publish = true">
                        mdi-check-outline
                      </v-icon>
                    </template>
                    <span>发布</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else-if="has_publish_right">
                    <template v-slot:activator="{ on }">
                      <v-icon color="error" v-on="on" @click="tempDelete = item;dialog_unpublish = true">
                        mdi-close-outline
                      </v-icon>
                    </template>
                    <span>取消发布</span>
                  </v-tooltip>
                  &nbsp;
                  <v-tooltip bottom v-if="item.status == 'INIT' && has_change_right">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="() => handleEditAdvice(item)">
                        mdi-pencil-circle-outline
                      </v-icon>
                    </template>
                    <span>编辑</span>
                  </v-tooltip>
                  &nbsp;
                  <v-tooltip bottom v-if="item.status == 'INIT' && has_delete_right">
                    <template v-slot:activator="{ on }">
                      <a v-on="on" @click="tempDelete = item;dialog_delete = true">
                        <v-icon color="error">
                          mdi-trash-can-outline
                        </v-icon>
                      </a>
                    </template>
                    <span>删除</span>
                  </v-tooltip>
                </v-toolbar>
                <div class="panel-details flex" v-for="detail in item.medicine_details" :key="detail.sid">
                  <div>
                    <img :src="detail.imgPath" />
                    <div v-if="detail.medicine_info.is_off_shelf" style="font-size:12px;color:red;">
                      *该营养素已下架，请尽快调整
                    </div>
                  </div>
                  <div class="flex1">
                    <p>
                      【品名】：<a>{{detail.medicine_info.display_name || detail.medicine_info.medicine_name}}</a>
                      <template v-if="detail.is_optional">(可选)</template>
                    </p>
                    <p>
                      【建议用量】：
                      {{detail.take_rate_display}}
                      服用
                      <!-- <template v-if="detail.take_count_min == detail.take_count_max">
                        {{detail.take_count_min}}
                      </template>
                      <template v-else>
                        {{detail.take_count_min}}-{{detail.take_count_max}}
                      </template> -->
                      {{detail.take_count_min}}
                      次，每次
                      {{detail.recommend_number}}
                      {{detail.recommend_unit_display}}
                      <!-- ，最大剂量
                      {{detail.max_dosage}}
                      {{detail.recommend_unit_display}} -->
                    </p>
                    <p v-for="prop in detail.medicine_info.property.filter(p => p.property_value.length > 0)" :key="prop.property_no" :title="prop.property_value.DESC || prop.property_value">
                      【{{prop.property_name}}】：{{prop.property_value.DESC || prop.property_value}}
                    </p>
                    <!-- <p v-if="detail.take_comments">【服用说明】：{{detail.take_comments}}</p> -->
                    <p v-if="detail.note">【备注】：{{detail.note}}</p>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog_delete" persistent max-width="290">
      <v-card>
        <v-card-title class="title">您确认要删除该记录吗?</v-card-title>
        <v-card-text>删除后该记录将无法还原，请您谨慎操作</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="dialog_delete = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleDeleteAdvice">我想好了，删</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_publish" persistent max-width="290">
      <v-card>
        <v-card-title class="title">您确认要发布吗?</v-card-title>
        <v-card-text>一旦发布，客户会在小程序端看到此方案。您确定发布吗？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="dialog_publish = false">取消</v-btn>
          <v-btn color="primary" text @click.native="handlePublishAdvice">确认发布</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_unpublish" persistent max-width="290">
      <v-card>
        <v-card-title class="title">您确认要取消发布吗?</v-card-title>
        <v-card-text>一旦取消发布，客户将无法在小程序端看到此方案。您确定取消发布吗？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="dialog_unpublish = false">取消</v-btn>
          <v-btn color="error" text @click.native="handleUnpublishAdvice">确认取消发布</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {api} from "@/utils/api";
import nf from "@/utils/notification"
import axios from 'axios'
export default {
  name: "customers-v2-suggest-index",
  props: {
    id: {
      type: String
    }
  },
  computed: {
    has_add_right() {
      return this.$store.getters.getPermission('individualmedicineadvice', 'add')
    },
    has_change_right() {
      return this.$store.getters.getPermission('individualmedicineadvice', 'change')
    },
    has_delete_right() {
      return this.$store.getters.getPermission('individualmedicineadvice', 'delete')
    },
    has_publish_right() {
      return this.$store.getters.getPermission('individualmedicineadvice', 'publish')
    }
  },
  data() {
    return {
      dietaryRecommenChart: null,
      dietaryData: {},
      // 推荐运动定义
      sportList: [
        { name: '快速跳绳', pic: require('@/assets/skipping.png'), energy: 640, minutes: 0 },
        { name: '自行车', pic: require('@/assets/riding.png'), energy: 518, minutes: 0 },
        { name: '慢跑', pic: require('@/assets/running.png'), energy: 491, minutes: 0 },
        { name: '游泳', pic: require('@/assets/swimming.png'), energy: 491, minutes: 0 },
        { name: '室内健身操', pic: require('@/assets/exercise.png'), energy: 446, minutes: 0 },
        { name: '羽毛球', pic: require('@/assets/badminton.png'), energy: 402, minutes: 0 },
        { name: '快走', pic: require('@/assets/walking.png'), energy: 357, minutes: 0 },
        { name: '普拉提', pic: require('@/assets/pilates.png'), energy: 178, minutes: 0 }
      ],
      // 营养素推荐
      medicineAdviceList: [],
      showAddMedicineAdvice: false,
      addLoading: false,
      addAdviceForm: {
        advice_name: '',
        note: '',
        medicine_details: []
      },
      tempDelete: null,
      dialog_delete: false,
      firstShopUrl: '',
      dialog_publish: false,
      dialog_unpublish: false
    }
  },
  mounted() {
    this.dietaryRecommenChart = this.$echarts.init(document.getElementById('dietaryrecommen'))

    this.buildDietaryRecommen()
    this.buildMedicineAdvice()

    let itv = setInterval(() => {
      if(this._isDestroyed) return clearInterval(itv)

      this.dietaryRecommenChart.resize()
    }, 1000)
  },
  methods: {
    async buildDietaryRecommen() {
      const dpr = window.devicePixelRatio || 1
      const data = await api.get(`individuals/${this.$route.params.id}/get_dietary_sport_suggest/`)
      const { dietaryRecommenChart } = this
      dietaryRecommenChart.on('finished', () => {
        const dplist = dietaryRecommenChart.getZr().storage._displayList.filter(dl => !!dl._textContent)
        const minX = dplist[1].path._x0
        const minY = dplist[1].path._y0
        const endX = dplist[2].path._x0
        const endY = dplist[2].path._y0
        const shape = dplist[0].shape

        const protein = dplist[4]
        const fat = dplist[5]
        const carbohydrate = dplist[6]

        const cvs = document.getElementById('dietaryrecommen').children[0].children[0]
        const ctx = cvs.getContext('2d')
        ctx.save()

        ctx.font = `${14 * dpr}px Roboto`
        ctx.textAlign = 'center'
        ctx.fillText(data.weight_min, (minX - 10) * dpr, (minY - 10) * dpr)
        ctx.fillText(data.weight_max, (endX + 10) * dpr, (endY - 10) * dpr)
        ctx.fillText(`>${data.weight_obese}`, shape.cx * dpr, (shape.cy + shape.r + 20) * dpr)
        ctx.font = `${16 * dpr}px Roboto`
        if (data.weight_delta > 0) {
          ctx.fillText(`${data.weight}公斤`, shape.cx * dpr, (shape.cy - 10) * dpr)
        } else {
          ctx.fillText(`${data.weight}公斤`, shape.cx * dpr, (shape.cy + 5) * dpr)
        }

        let proteinX =  (protein.path._x0 + fat.path._x0) / 2
        let proteinY = (protein.path._y0 + fat.path._y0) / 2
        let fatX = (carbohydrate.path._x0 + fat.path._x0) / 2
        let fatY = (carbohydrate.path._y0 + fat.path._y0) / 2
        let carbohydrateX = carbohydrate.shape.cx
        let carbohydrateY = carbohydrate.shape.cy + carbohydrate.shape.r + 20
        if (data.type === 'sport') {
          proteinX = proteinX + 25
          proteinY = proteinY - 5
          fatX = fatX + 15
          fatY = fatY - 35
        } else if (data.type === 'dm') {
          proteinX = proteinX - 45
          proteinY = proteinY - 15
          fatX = fatX + 55
          fatY = fatY - 35
        } else {
          proteinX = proteinX - 30
          proteinY = proteinY - 10
          fatX = fatX + 40
          fatY = fatY - 30
        }
        ctx.fillText(data.protein, proteinX * dpr, proteinY * dpr)
        ctx.fillText(data.fat, fatX * dpr, fatY * dpr)
        ctx.fillText(data.carbohydrate, carbohydrateX * dpr, carbohydrateY * dpr)
        ctx.fillText(`${data.overall_energy}大卡`, carbohydrate.shape.cx * dpr, (carbohydrate.shape.cy + 7) * dpr)

        if (data.weight_delta > 0) {
          ctx.beginPath()
          ctx.lineJoin = 'round'
          ctx.lineWidth = 10
          ctx.rect((shape.cx - 40) * dpr, (shape.cy + 5) * dpr, 80 * dpr, 16 * dpr)
          ctx.strokeStyle = '#F08300'
          ctx.fillStyle = '#F08300'
          ctx.stroke()
          ctx.fill()

          ctx.font = `${14 * dpr}px 微软雅黑`
          ctx.fillStyle = '#ffffff'
          ctx.fillText(`减重${data.weight_delta}公斤`, shape.cx * dpr, (shape.cy + 18) * dpr)
        }
        ctx.restore()
      })
      let startAngle = -180
      let proteinPercent = 20
      let proteinDeg = 50
      let fatPercent = 30
      let fatDeg = -45
      let carbohydratePercent = 50
      let carbohydrateDeg = 0
      if (data.type === 'sport') {
        startAngle = -200
        proteinPercent = 10
        proteinDeg = 50
        fatPercent = 25
        fatDeg = -15
        carbohydratePercent = 60
      } else if (data.type === 'dm') {
        startAngle = -170
        proteinPercent = 22.5
        proteinDeg = 60
        fatPercent = 35
        fatDeg = -45
        carbohydratePercent = 42.5
      }
      dietaryRecommenChart.setOption({
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            center: ['25%', '50%'],
            startAngle: -170,
            hoverAnimation: false,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              position: 'inner',
              color: '#fff',
              fontSize: 14
            },
            data: [
              { value: 50, name: '偏瘦', itemStyle: { color: '#00B4ED' }, label: { rotate: 80 } },
              { value: 100, name: '健康体重', itemStyle: { color: '#60B630' } },
              { value: 50, name: '超重', itemStyle: { color: '#F08300' }, label: { rotate: -80 } },
              { value: 160, name: '肥胖', itemStyle: { color: '#E94831' } }
            ]
          },
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            center: ['75%', '50%'],
            startAngle,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              position: 'inner',
              color: '#fff',
              fontSize: 14
            },
            data: [
              { value: proteinPercent, name: '蛋白质', itemStyle: { color: '#F08300' }, label: { rotate: proteinDeg } },
              { value: fatPercent, name: '脂肪', itemStyle: { color: '#00B4ED' }, label: { rotate: fatDeg } },
              { value: carbohydratePercent, name: '碳水化合物', itemStyle: { color: '#60B630' }, label: { rotate: carbohydrateDeg } }
            ]
          }
        ]
      })
      this.dietaryData = data
      this.sportList.map(sport => {
        const minutes = Math.round(60 * data.total_k_cal / sport.energy)
        if (['快走', '普拉提'].includes(sport.name)) {
          if (minutes < 90) {
            sport.minutes = minutes
          }
        } else  {
          sport.minutes = minutes
        }
      })
    },
    async buildMedicineAdvice() {
      this.medicineAdviceList = await api.get(`individual_medicine_advice/?i=${this.$route.params.id}`)
      // const firstShopUrl = this.medicineAdviceList.find(mal => !!mal.shop_url)
      // if (firstShopUrl) {
      //   this.firstShopUrl = firstShopUrl.shop_url
      // }
      this.buildMedicineAdviceDetail(this.medicineAdviceList[0])
    },
    async handleLoadAdviceDetail(advice) {
      this.buildMedicineAdviceDetail(advice)
    },
    async buildMedicineAdviceDetail(advice) {
      if (!advice || advice.medicine_details) return
      advice.medicine_details = await api.get(`individual_medicine_advice/list_advice_detail/?ai=${advice.sid}`)
      const propertySort = [
        { no: 'P-SPECIFICATION', desc: '规格' },
        { no: 'P-INGREDIENT', desc: '成份' },
        { no: 'P-EFFICACY', desc: '功效' },
        { no: 'P-PROMPT', desc: '注意事项' },
      ]
      for(let a of advice.medicine_details) {
        const property = []
        propertySort.map(p => {
          const prop = a.medicine_info.property.find(prop => prop.property_no == p.no)
          if (prop) {
            prop.property_name = p.desc
            if (prop.property_value.DESC) {
              prop.property_value = prop.property_value.DESC
            }
            if (prop.property_value && prop.property_value.length > 0) {
              property.push(prop)
            }
          }
        })
        a.medicine_info.property = property

        try {
          const imgPath = `/static/images/medicine/${a.medicine_info.medicine_no}.jpg`
          await axios.get(imgPath)
          a.imgPath = imgPath
        } catch(e) {
          a.imgPath = '/static/images/medicine/medicineplace.jpg'
          // console.warning('没有营养素图片')
        }
      }
      this.$forceUpdate()
    },
    handleAddAdvice() {
      this.addAdviceForm = {
        advice_name: '',
        // shop_url: this.firstShopUrl,
        note: '',
        medicine_details: []
      }
      this.showAddMedicineAdvice = true
    },
    handleEditAdvice(advice) {
      const adv = JSON.parse(JSON.stringify(advice))
      adv.medicine_details.map(md => {
        md.medicine_info.medicine_fixed_name = `[${md.medicine_info.medicine_no}]${md.medicine_info.medicine_name}`
        if (md.medicine_info.is_off_shelf) {
          md.medicine_info.medicine_fixed_name += ' - 已下架'
        }
        md.tempData = {
          searchedData: [md.medicine_info],
          searchLoading: false,
          searchKeyword: md.medicine_info.medicine_no,
          searchedObj: md.medicine_info,
          unitOptions: md.medicine_info.unit.map(r => ({ value: r[0], text: r[1] })),
          rateOptions: md.medicine_info.rate.map(r => ({ value: r[0], text: r[1] }))
        }
        md.medicine = md.medicine_info.sid
      })
      this.addAdviceForm = adv
      this.showAddMedicineAdvice = true
    },
    async handleDeleteAdvice() {
      this.dialog_delete = false
      await api.delete(`individual_medicine_advice/${this.tempDelete.sid}/`)
      nf.showSuccessMsg(`删除成功！`)
      this.buildMedicineAdvice()
    },
    async handlePublishAdvice() {
      this.dialog_publish = false
      await api.post(`individual_medicine_advice/${this.tempDelete.sid}/advice_publish/`)
      nf.showSuccessMsg(`发布成功！`)
      this.buildMedicineAdvice()
    },
    async handleUnpublishAdvice() {
      this.dialog_unpublish = false
      await api.post(`individual_medicine_advice/${this.tempDelete.sid}/advice_revoke/`)
      nf.showSuccessMsg(`取消发布成功！`)
      this.buildMedicineAdvice()
    },
    async handleSearchInput(item) {
      const { tempData } = item
      if (tempData.searchLoading || !tempData.searchKeyword || tempData.searchKeyword.indexOf('[') == 0) return
      tempData.searchLoading = true
      let results = await api.get(`medicines/advice/?keyword=${tempData.searchKeyword}&limit=10`)
      results.map(rs => rs.medicine_fixed_name = `[${rs.medicine_no}]${rs.medicine_name}`)
      tempData.searchedData = results
      tempData.searchLoading = false
    },
    handleSearchChange(item) {
      const { tempData } = item
      item.medicine = tempData.searchedObj.sid
      tempData.rateOptions = tempData.searchedObj.rate.map(r => ({ value: r[0], text: r[1] }))
      tempData.unitOptions = tempData.searchedObj.unit.map(r => ({ value: r[0], text: r[1] }))

      if (tempData.searchedObj.property.length > 0) {
        const baseAttr = tempData.searchedObj.property.find(p => p.property_no == 'P-DISPLAY-VALUE')
        if (baseAttr) {
          Object.keys(baseAttr.property_value).map(key => {
            if(key == 'take_count_max'){
              // 个性化干预，直接明确每天吃几次，只填最小推荐值一个字段
              item[key] = null
            }else{
              item[key] = baseAttr.property_value[key]
            }
          })
        }

        // const efficacy = tempData.searchedObj.property.find(p => p.property_no == 'P-EFFICACY')
        // if (efficacy) {
        //   tempData.efficacy = efficacy.property_value.DESC
        // }

        // const ingredient = tempData.searchedObj.property.find(p => p.property_no == 'P-INGREDIENT')
        // if (ingredient) {
        //   tempData.ingredient = ingredient.property_value.DESC
        // }
        
        // const specification = tempData.searchedObj.property.find(p => p.property_no == 'P-SPECIFICATION')
        // if (specification) {
        //   tempData.specification = specification.property_value.DESC
        // }
      }
    },
    handleAddMedicine() {
      this.addAdviceForm.medicine_details.push({
          tempData: {
            searchedData: [],
            searchLoading: false,
            searchKeyword: '',
            searchedObj: {},
            unitOptions: [],
            rateOptions: []
          },

          medicine: null, // 营养素id
          recommend_number: 0, // 服用数量
          recommend_unit: '', // 服用数量单位
          take_rate: '', // 服用频率
          take_count_min: 0, // 最小服用次数
          take_count_max: 0, // 最大服用次数
          max_dosage:  0, // 最大服用剂量
          take_comments: '', // 服用说明
          is_optional: false, // 是否可选
          note:  '' // 备注
        })
    },
    handleRemoveAdvice(item, i) {
      this.addAdviceForm.medicine_details.splice(i, 1)
    },
    async handleSave() {
      const { addAdviceForm } = this
      if (!addAdviceForm.advice_name) {
        nf.showWarnMsg(`请输入推荐主题`)
        return
      }
      for(let i = 0; i < addAdviceForm.medicine_details.length; i++) {
        if (!addAdviceForm.medicine_details[i].medicine) {
          addAdviceForm.medicine_details.splice(i, 1)
        }
      }
      if (addAdviceForm.medicine_details.length == 0) {
        nf.showWarnMsg(`您尚未添加营养素`)
        return
      }
      let ok = true
      for(let i = 0; i < addAdviceForm.medicine_details.length; i++) {
        const temp = addAdviceForm.medicine_details[i]
        if (!temp.recommend_number || 
          !temp.recommend_unit || 
          !temp.take_rate || 
          !temp.take_count_min) {
          nf.showWarnMsg(`${temp.tempData.searchedObj.medicine_name}的服用剂量未填写完整`)
          ok = false
        }
      }
      if (!ok) {
        return
      }
      const postData = {
        individual: this.$route.params.id,
        advice_name: addAdviceForm.advice_name,
        // shop_url: addAdviceForm.shop_url,
        note: addAdviceForm.note,
        medicine_details: addAdviceForm.medicine_details.map((md, i) => {
          return {
            medicine: md.medicine,
            recommend_number: md.recommend_number,
            recommend_unit: md.recommend_unit,
            take_rate: md.take_rate,
            take_count_min: md.take_count_min,
            take_count_max: md.take_count_min,
            max_dosage: md.max_dosage,
            take_comments: md.take_comments,
            is_optional: md.is_optional,
            note: md.note,
            order_no: i
          }
        })
      }
      this.addLoading = true
      if (addAdviceForm.sid) {
        await api.put(`individual_medicine_advice/${addAdviceForm.sid}/`, postData)
      } else {
        await api.post(`individual_medicine_advice/`, postData)
      }
      nf.showSuccessMsg(`保存成功！`)
      this.buildMedicineAdvice()
      this.showAddMedicineAdvice = false
      this.addLoading = false
    }
  }
};
</script>
<style lang="scss">
.nutrientitem .detail {
  margin-top: 8px;
  .v-text-field {
    display: inline-block;
    margin: 0 4px;
    padding: 0;
    vertical-align: middle;
    input {
      text-align: center;
    }
  }
}
</style>
<style scoped lang="scss">
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
  .panelcard {
    width: 100%;
    padding: 16px;
    margin-bottom: 24px;
    #dietaryrecommen {
      pointer-events: none;
      height: 264px;
    }
    img {
      width: 100%;
    }
    p {
      font-size:14px;
      margin: 4px 0 4px 16px;
      text-indent:16px;
    }
  }
  .nutrientcard {
    padding: 16px;
    .flex {
      display: flex;
      align-items: center;
    }
    .flex1 {
      flex: 1;
    }
    .add-advice {
      .nutrientitem {
        margin: 16px;
        .desc {
          overflow: hidden;
          height: 20px;
          margin: 8px 0;
        }
      }
    }
    .panel-details {
      margin-bottom: 16px;
      img {
        height: 200px;
        margin-right: 16px;
      }
      p {
        margin: 8px 0;
        max-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .daysuggest{
    padding: 0 0 16px;
    &-row {
      margin-top: 16px;
      display: flex;
      &-f1 {
        flex: 1;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        div { flex: 1; }
      }
      .mainimg {
        width: auto;
        height: 66px;
        margin-right: 16px;
      }
      .numimg {
        width: 40px;
        margin: 0 16px 0 0;
      }
    }
  }
  .topbtn {
    position: absolute;
    top: 32px;
    right: 8px;
  }
</style>